import React, { useEffect } from 'react'
import 'twin.macro'
import { navigate } from 'gatsby'

// import bg1 from '../assets/images/landing-page-tet/product-group/bg-1.jpg'
// import bg2 from '../assets/images/landing-page-tet/product-group/bg-2.jpg'
// import bg3 from '../assets/images/landing-page-tet/product-group/bg-3.jpg'
// import bg4 from '../assets/images/landing-page-tet/product-group/bg-4.jpg'

import TailwindLayout from '../components/Layouts/TailwindLayout'
import TopMenu from '../components/BanhKeoTetPage/TopMenu'
import HeroSection from '../components/BanhKeoTetPage/HeroSection'
import FeatureSection from '../components/BanhKeoTetPage/FeatureSection'
import DiscountSection from '../components/BanhKeoTetPage/DiscountSection'
import Footer from '../components/Base/Footer'
import SEOComponent from '../components/SEOComponent'
import ToastContainer from '../components/ToastContainer'
import ModalViewProduct from '../components/Base/ProductGroup/ModalViewProduct'
import CartIcon from '../components/Base/CartIcon'
import { ImageDataContext } from '../states/imageDataContext'

export default function BanhKeoTetPage() {
  useEffect(() => {
    navigate('/?viewId=tet-collection')
  }, [])

  // const groups = _.reduce(
  //   _.groupBy(tetProducts, 'group'),
  //   (result, groupedTetProducts, groupName) => {
  //     const group = {
  //       title: groupName,
  //       groupedTetProducts,
  //     }
  //     result.push(group)
  //     return result
  //   },
  //   []
  // )
  const imagesData = []
  // const imagesData =   //   {
  //     allS3Object(
  //       filter: { Key: { regex: "/images/products/banh-keo-tet/" } }
  //       sort: { fields: [Key], order: ASC }
  //     ) {
  //       totalCount
  //       edges {
  //         node {
  //           Key
  //           localFile {
  //             name
  //             childrenImageSharp {
  //               gatsbyImageData(width: 2000)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  // const nodeName = getNodeNameByData(imagesData)
  const nodeName = ''

  return (
    <ImageDataContext.Provider value={imagesData}>
      <TailwindLayout tw="bg-white">
        <ToastContainer />
        <CartIcon />
        <ModalViewProduct nodeName={nodeName} />
        <TopMenu />
        <HeroSection />
        <FeatureSection labelId="gioithieu" />
        {/* <TetProductGroup
          labelId="danhmuc"
          data={groups[0].groupedTetProducts}
          title={groups[0].title.toUpperCase()}
          description="Savor lựa chọn nguyên liệu cao cấp nhất như bơ TH true, marshmallow xylitol không đường kính, hạt hạnh nhân, óc chó Olam, hạt macca, hạt điều nguyên hạt... tạo nên sự biệt hoàn toàn so với các dòng sản phẩm đại trà."
          bg={bg1}
          autoplay={false}
        />

        <TetProductGroup
          data={groups[1].groupedTetProducts}
          title={groups[1].title.toUpperCase()}
          description="Bánh bông tuyết được làm tự kẹo xylitol không chứa đường kính. Phù hợp với hương vị đặc trưng của bánh quy mặn, Savor lựa chọn 3 hương vị đậm đà hương vị Tết: bò khô, cà ri gà và trứng muối chà bông, giúp tạo nên điểm nhất khác biệt cho món quà của Bạn."
          bg={bg2}
        />
        <TetProductGroup
          data={groups[2].groupedTetProducts}
          title={groups[2].title.toUpperCase()}
          description="Bánh thuyền với các phiên bản mix hạt dinh dưỡng khác nhau là món hot trend trong những năm gần đây."
          bg={bg3}
        />
        <TetProductGroup
          data={groups[3].groupedTetProducts}
          title={groups[3].title.toUpperCase()}
          description="Savor lựa chọn các dòng bánh xịn nhất, xịn từ nguyên liệu đến hương vị, đem đến sự đa dạng cho món quà Tết của Bạn."
          bg={bg4}
        /> */}
        <DiscountSection labelId="chietkhau" />
        {/* <FeedbackSection labelId="feedback" /> */}
        <Footer bgColor="#810D07" />
        <SEOComponent
          title="Savor Cake | Kẹo nougat - Bánh kẹo quà Tết"
          description="Lựa chọn các vị kẹo nougat, bánh kẹo quà Tết healthy, đã dạng, ít đường và giàu dinh dưỡng, được làm từ nguyên liệu cao cấp, không sử dụng chất bảo quản"
          keywords="kẹo nougat, bánh bông tuyết, bánh thuyền, snack rong biển, bánh dứa, bánh kẹo Tết"
        />
      </TailwindLayout>
    </ImageDataContext.Provider>
  )
}
