import * as React from 'react'
import { useRef, useState, useLayoutEffect } from 'react'
import { Link } from 'react-scroll'
import mobileBackground from '../../assets/svg/banh-keo-tet/hero-section/background-mobile.png'
import desktopBackground from '../../assets/svg/banh-keo-tet/hero-section/background-desktop.png'
import discountPanel from '../../assets/svg/banh-keo-tet/top-menu/chiet-khau-panel.png'
import { StaticImage } from 'gatsby-plugin-image'
import { MotionButton, Container, Item } from '../Animation'
import { styled } from 'twin.macro'

export default function HeroSection() {
  const ref = useRef(null)
  const [, setHeight] = useState(0)
  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [])

  return (
    <Wrapper>
      <div tw="relative pb-24">
        <div
          tw="lg:container p-0 mx-auto
            lg:max-w-screen-xl! md:w-full 
            md:flex-col md:justify-between md:px-12
            pt-20 md:pt-2 relative

      "
        >
          <div
            tw="font-bold flex-col items-center justify-center
          md:justify-center md:items-center
        "
          >
            <Container>
              <Item>
                <div
                  tw="text-tet-yellow-500 font-playFairSerif
                  text-3xl sm:text-4xl text-center md:text-4xl lg:text-5xl xl:text-6xl
                  mt-8"
                >
                  Quà Tết Handmade
                  <br /> Chọn Ngay Savor
                </div>
              </Item>
              <Item>
                <Link to="combo" smooth={true}>
                  <MotionButton>
                    <Panel
                      tw="mt-8 mb-12 mx-12 flex justify-center items-center text-tet-red-500
                      text-sm sm:text-base md:mx-4 cursor-pointer
                      md:hidden
                      "
                    ></Panel>
                  </MotionButton>
                </Link>
              </Item>
            </Container>
          </div>

          {/* ----- product MOBILE ------ */}
          <div
            tw="mx-2 my-2 flex items-center justify-center md:hidden
            px-8
        "
          >
            <StaticImage
              src="../../assets/svg/banh-keo-tet/hero-section/product-mobile.png"
              alt="Background hero section mobile"
            />
          </div>

          {/* ----- product desktop ------ */}
          <div
            tw="md:px-16 xl:px-24 my-2 items-center justify-center
                hidden md:flex md:justify-end md:items-end
                w-full
                mt-8 2xl:mb-16
        "
          >
            <StaticImage
              src="../../assets/svg/banh-keo-tet/hero-section/product-desktop.png"
              alt="Background hero desktop"
            />
          </div>
        </div>

        <div
          ref={ref}
          tw="hidden sm:w-full sm:absolute sm:block sm:-bottom-20 md:-bottom-28 lg:-bottom-36 xl:-bottom-44 2xl:-bottom-56 pointer-events-none"
        >
          <StaticImage
            src="../../assets/svg/banh-keo-tet/hero-section/cloud.png"
            layout="fullWidth"
            alt="ảnh mây"
          />
        </div>

        <div ref={ref} tw="absolute w-full sm:hidden">
          <StaticImage
            src="../../assets/svg/banh-keo-tet/hero-section/hero-cloud-mobile.png"
            layout="fullWidth"
            alt="ảnh mây"
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-image: url(${mobileBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -40px;
  background-color: #fdaf1b;
  @media screen and (min-width: 768px) {
    background-image: url(${desktopBackground});
    background-position-y: 0px;
  }
`

const Panel = styled.div`
  background-image: url(${discountPanel});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 56px;
`
