import tw, { css, styled } from 'twin.macro'

import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { revealVariants, buttonVariants } from '../../utils/motion'
import { useEffect } from 'react'

const VoucherItem = tw.div`flex flex-col bg-transparent bg-white`
const VoucherTitle = styled.h2`
  ${css`
    ${tw`
    bg-savor-cake-primary-beige-200 text-tet-red-500 font-playFairSerif font-extrabold text-sm text-center py-4 pb-8 px-1 m-0 leading-normal
      md:text-2xl lg:text-4xl lg:py-12 lg:pb-16 lg:px-1.5
      xl:text-4xl`}
  `}
`
const VoucherDescription = styled.ul`
  ${css`
    ${tw`relative bg-savor-cake-primary-beige-200 font-semibold text-black font-loraSerif text-xs leading-normal
      border-t-2 border-tet-yellow-500 border-dashed
      py-2 px-4
      md:text-sm! md:px-8
      lg:text-xl! lg:border-t-2 lg:px-8 lg:py-4 lg:font-bold
      xl:px-12
    `}
  `}
`

export default function DiscountSection({ labelId }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div tw="bg-tet-red-400 pt-10 lg:pt-14" id={labelId}>
        <Wrapper id="datbanh" tw="lg:pt-14 xl:pt-16">
          <div tw="p-0 mx-auto px-4 pb-4 lg:max-w-screen-xl! lg:container">
            <div tw="p-4 mb-6 md:hidden">
              <div tw="flex justify-center items-center">
                <h2 tw="text-tet-red-400 font-playFairSerif font-extrabold text-2xl flex-shrink-0">
                  Vui Xuân Đón Tết
                </h2>
                <div tw="ml-4 bg-tet-red-400 block h-0.5 w-1/3 items-end" />
              </div>

              <div tw="flex justify-center items-center">
                <div tw="mr-4 bg-tet-red-400 block h-0.5 w-1/3 items-end" />
                <h2 tw="text-tet-red-400 font-playFairSerif font-extrabold text-2xl flex-shrink-0">
                  Ưu Đãi Cực Lớn
                </h2>
              </div>
            </div>
            <h2
              tw="text-tet-red-400 font-playFairSerif font-extrabold m-auto text-center 
              pt-12 lg:pt-20
              pb-10 lg:pb-16
              text-3xl lg:text-5xl hidden md:block"
            >
              Vui Xuân Đón Tết - Ưu Đãi Cực Lớn
            </h2>
            <div tw="grid grid-cols-2 gap-4 sm:gap-8 sm:px-6 md:gap-14 md:px-12 lg:gap-20 lg:px-20">
              {/* Tui banh */}
              <VoucherItem
                className="group"
                tw="transition-all duration-500 ease-in-out shadow-xl"
              >
                <div tw="w-full self-center transition-all duration-500 ease-in-out group-hover:w-21/50 group-hover:border-2 group-hover:border-white group-hover:shadow-xl">
                  <StaticImage
                    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/mua-6-tang-1.jpg"
                    alt="hộp bánh tết 6 lọ"
                  ></StaticImage>
                </div>
                <VoucherTitle tw="transition-all w-full self-center duration-500 ease-in-out group-hover:w-21/50">
                  Mua 6 tặng 1
                </VoucherTitle>

                <VoucherDescription tw="transition-all w-full h-full self-center duration-500 ease-in-out group-hover:w-21/50">
                  <div tw="absolute top-0 left-0 w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20">
                    <div tw="absolute rounded-1/2 bg-tet-yellow-600 w-full h-full -left-1/2 -top-1/2"></div>
                  </div>
                  <div tw="absolute top-0 right-0 w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20">
                    <div tw="absolute rounded-1/2 bg-tet-yellow-600  w-full h-full -right-1/2 -top-1/2"></div>
                  </div>
                  <li tw="relative mt-2 mb-2">
                    <span tw="pl-2 lg:pl-4">
                      Tặng bộ túi hộp khi chọn mua 6 lọ thủy tinh bất kì
                      (nougat, bánh bông tuyết, bánh snack).
                    </span>
                  </li>
                </VoucherDescription>
              </VoucherItem>

              {/* voucher */}

              <VoucherItem
                className="group"
                tw="transition-all duration-500 ease-in-out"
              >
                <div tw="w-full self-center transition-all duration-500 ease-in-out group-hover:bg-white group-hover:w-21/50 group-hover:border-2 group-hover:border-white group-hover:shadow-xl">
                  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/chiet-khau.jpg"></StaticImage>
                </div>
                <VoucherTitle tw="transition-all w-full self-center duration-500 ease-in-out group-hover:w-21/50">
                  Chiết khấu lớn 25 - 45%
                </VoucherTitle>

                <VoucherDescription tw="transition-all w-full h-full self-center duration-500 ease-in-out group-hover:w-21/50">
                  <div tw="absolute top-0 left-0 w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20">
                    <div tw="absolute rounded-1/2 bg-tet-yellow-600  w-full h-full -left-1/2 -top-1/2"></div>
                  </div>
                  <div tw="absolute top-0 right-0 w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20">
                    <div tw="absolute rounded-1/2 bg-tet-yellow-600 w-full h-full -right-1/2 -top-1/2"></div>
                  </div>
                  <li tw="relative my-2">
                    <span tw="pl-2 lg:pl-4">
                      Áp dụng khi khách hàng mua từ 3kg hoặc 2tr trở lên.
                    </span>
                  </li>
                </VoucherDescription>
              </VoucherItem>
            </div>
            <motion.div variants={buttonVariants} whileHover="hover">
              <a
                tw="grid mt-16 mb-8 justify-center items-center transition-all duration-500 ease-in-out
              lg:mt-32
              "
                className="group"
                href="https://m.me/savor.vn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div tw="w-60 xl:w-96  row-start-1 col-start-1 transition-all duration-500 ease-in-out group-hover:w-72 lg:group-hover:w-96 lg:w-80">
                  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/Frame.png"></StaticImage>
                </div>
                <p tw="row-start-1 col-start-1 font-playFairSerif text-tet-yellow-500 transition-all duration-500 ease-in-out group-hover:text-tet-yellow-600 text-xl font-bold lg:text-3xl z-0 text-center">
                  Đặt bánh ngay
                </p>
              </a>
            </motion.div>
          </div>
          <div tw="hidden sm:flex border-0">
            <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/desktopCloud.png" />
          </div>
          <div tw="flex sm:hidden border-0">
            <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/mobileCloud.png" />
          </div>
        </Wrapper>
      </div>
    </motion.div>
  )
}

const Wrapper = styled.div`
  background-image: url(${'https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/background-mobile.png'});
  background-size: 100% 100%;
  background-color: #c0170f;
  padding-top: 36px;

  @media screen and (min-width: 768px) {
    background-image: url(${'https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-tet/discount-section/background-desktop.png'});
    padding-top: 60px;
  }
`
