import React, { useEffect, useRef } from 'react'
import { Link } from 'react-scroll'
import tw from 'twin.macro'
import { FaBars, FaChevronLeft } from 'react-icons/fa'
import { StaticImage } from 'gatsby-plugin-image'

export default function TopMenu() {
  const [showMenu, setShowMenu] = React.useState(false)
  const menuItems = [
    { label: 'Giới thiệu', id: 'gioithieu' },
    { label: 'Danh mục', id: 'danhmuc' },
    { label: 'Ưu đãi', id: 'chietkhau' },
    { label: 'Feedback', id: 'feedback' },
  ]
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div
      tw="
          md:bg-tet-red-800"
    >
      <div
        tw="
        lg:container p-0 mx-auto px-4
      lg:max-w-screen-xl!
      w-full md:h-20 lg:h-24
      text-center h-12
      absolute md:relative
      top-4 md:top-0
    "
      >
        <div
          tw="grid gap-4
              grid-cols-3 md:grid-cols-2
              h-full
              md:px-2 lg:px-8"
          ref={wrapperRef}
        >
          <div tw="order-first md:hidden pt-4 z-10">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              tabIndex="-1"
              role="button"
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            >
              {showMenu ? (
                <FaChevronLeft color="#fff" size={24} />
              ) : (
                <FaBars color="#fff" size={24} />
              )}
            </div>
          </div>

          <div
            tw="justify-self-center w-full
                col-start-2 col-span-1
                md:col-start-1 md:col-span-1
                md:order-first md:justify-self-start
                md:flex items-center
        "
          >
            <StaticImage
              src="../../assets/images/logos/savor-cakes-logo-green.png"
              alt="Logo Savor Cake"
              height={75}
            />
            <div
              tw="hidden md:flex w-full h-full
                    justify-center items-center"
            >
              <div
                tw="hidden md:block
                    md:w-56 lg:w-64 xl:w-72
                    "
              >
                <StaticImage
                  src="../../assets/svg/banh-keo-tet/top-menu/chiet-khau-panel.png"
                  alt="Chiết khấu lên đến 45%"
                />
              </div>
            </div>
          </div>

          {/* mobile menu */}
          <ul
            tw="md:hidden bg-tet-yellow-500 z-10
              w-1/3 rounded-md
              absolute top-full left-8"
            css={[showMenu ? tw`block` : tw`hidden`]}
          >
            {menuItems.map((item, index) => {
              return (
                item.id !== 'cart' && (
                  <div
                    tw="flex justify-start pl-2 py-2"
                    css={[
                      index !== menuItems.length - 2
                        ? tw` border-b border-solid border-tet-red-500`
                        : tw``,
                    ]}
                    key={index}
                  >
                    <Link
                      tw="w-full text-left text-tet-red-500 font-playFairSerif font-bold"
                      to={item.id}
                      smooth={true}
                      duration={400}
                    >
                      {item.label}
                    </Link>
                  </div>
                )
              )
            })}
          </ul>

          {/* desktop menu */}
          <ul
            tw="hidden gap-4
                  md:flex md:justify-between md:items-center
                  md:col-start-2 md:col-span-1"
          >
            {menuItems.map((item, index) => {
              return (
                <Link
                  tw="cursor-pointer px-2 font-playFairSerif text-white md:text-sm lg:text-xl hover:text-yellow-400"
                  to={item.id}
                  smooth={true}
                  key={index}
                >
                  {item.label}
                </Link>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
