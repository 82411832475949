import React from 'react'
import { motion } from 'framer-motion'
const Animation = ({ children }) => {
  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  )
}

export default Animation

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: { x: -30, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
}

export const Container = ({ children }) => {
  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      {children}
    </motion.div>
  )
}

export const Item = ({ children }) => {
  return <motion.div variants={item}>{children}</motion.div>
}

export const MotionButton = ({ children }) => {
  return (
    <motion.div variants={buttonVariants} whileHover="hover">
      {children}
    </motion.div>
  )
}

const buttonVariants = {
  hover: {
    y: [0, -10],
    transition: {
      yoyo: Infinity,
    },
  },
}
