import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { styled } from 'twin.macro'
import mobileBackground from '../../assets/svg/banh-keo-tet/feature-section/background-mobile.png'
import desktopBackground from '../../assets/svg/banh-keo-tet/feature-section/background-desktop.png'
import mobilePanel from '../../assets/svg/banh-keo-tet/feature-section/panel-mobile.png'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { revealVariants } from '../../utils/motion'
import { useEffect } from 'react'

export default function HeroSection({ labelId }) {
  const feature = [
    {
      description: 'Nguyên liệu cao cấp giàu dinh dưỡng, ít ngọt',
      image: (
        <StaticImage src="../../assets/svg/banh-keo-tet/feature-section/ingredient.png" />
      ),
    },
    {
      description: '100% Sản phẩm Handmade không chứa chất bảo quản',
      image: (
        <StaticImage src="../../assets/svg/banh-keo-tet/feature-section/hand-made.png" />
      ),
    },
    {
      description: 'Có túi hộp tặng kèm phù hợp làm quà biếu tặng',
      image: (
        <StaticImage src="../../assets/svg/banh-keo-tet/feature-section/red-envelope.png" />
      ),
    },
    {
      description: 'Chiết khấu lớn từ 20 - 40% khi mua giá sỉ',
      image: (
        <StaticImage src="../../assets/svg/banh-keo-tet/feature-section/discount.png" />
      ),
    },
  ]

  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <Wrapper id={labelId}>
        <div
          tw="lg:container mx-auto px-4
            lg:max-w-screen-xl!
            "
        >
          <h2
            tw="font-bold text-tet-red-600 text-center font-YesevaOneCursive
        px-6 sm:px-24 lg:pb-8
        text-2xl sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl 
        "
          >
            Quà Tết Handmade - Chọn Ngay Savor
          </h2>
          <Panel
            tw="
          my-2 py-4
          "
          >
            <div
              tw="flex flex-wrap justify-center items-center
          my-2 md:my-8 xl:my-16
          "
            >
              {feature.map((item, index) => {
                return (
                  <div
                    tw="rounded-md bg-tet-yellow-400
                              w-2/5 md:w-[21%]
                              my-1 mx-1 
                              pb-4
            "
                  >
                    <div tw="px-8 my-4 lg:my-8">{item.image}</div>

                    <h2
                      tw="font-loraSerif mt-0 text-center
                    h-12 sm:h-24 md:h-16 lg:h-24
                    mx-2
                    text-xs sm:text-lg md:text-xs lg:text-lg xl:text-xl
                    "
                    >
                      {item.description}
                    </h2>
                  </div>
                )
              })}
            </div>
          </Panel>
        </div>
      </Wrapper>
    </motion.div>
  )
}

const Wrapper = styled.div`
  background-image: url(${mobileBackground});
  background-size: 100% 100%;
  background-color: #c0170f;
  padding-top: 36px;
  padding-bottom: 76px;

  @media screen and (min-width: 414px) {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  @media screen and (min-width: 414px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @media screen and (min-width: 768px) {
    background-image: url(${desktopBackground});
    background-position-y: 0px;
  }
`

const Panel = styled.div`
  background-image: url(${mobilePanel});
  background-size: 100% 100%;
`
